import axiosInstance from '@api';
import { SearchDto } from '@dto';
import { SubjectAlbumDto } from '@dto/SubjectAlbumDto';

/**
 * Course를 기준으로 앨범 목록을 가져온다.
 * @param search
 * @returns
 */
export const list = (
  search: SearchDto,
): Promise<{
  count: number;
  data: SubjectAlbumDto[];
  hasNextPage: boolean;
  totalPage: number;
}> => axiosInstance.post('/subject/album/list', search);

/**
 * 주제의 앨범을 추가한다
 * @param subjectAlbum
 * @returns
 */
export const create = (subjectAlbum: SubjectAlbumDto): Promise<SubjectAlbumDto> =>
  axiosInstance.post('/subject/album', subjectAlbum);

/**
 * 앨범을 수정한다
 * @param id
 * @param subjectAlbum
 * @returns
 */
export const update = (id: number, subjectAlbum: SubjectAlbumDto): Promise<SubjectAlbumDto> =>
  axiosInstance.patch(`/subject/album/${id}`, subjectAlbum);

/**
 * 앨범 상세를 가져온다
 * @param id
 * @returns
 */
export const getById = (id: number, isEval?: boolean): Promise<SubjectAlbumDto> => axiosInstance.get(`/subject/album/${id}?isEval=${isEval}`);

/**
 * 앨범 삭제
 * @param id
 * @returns
 */
export const removeById = (id: number): Promise<{ count: number }> => axiosInstance.delete(`/subject/album/${id}`);
import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from '@headlessui/react';

import * as albumApi from '@api/album';

import { AlbumForm } from '@components/AlbumForm';
import { AlbumDetail } from '@components/AlbumDetail';
import { SubjectAlbumDto } from '@dto';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@store/hook';
import { open as modalOpen } from '@store/modal';
import { TailSpin } from 'react-loader-spinner';

interface IProp {
  readonly albumId: number | undefined;
  readonly isEval: boolean;
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose?: () => void;
  readonly onUpdated: () => void;
}

export const AlbumModal: React.FC<IProp> = ({ isEval, albumId, isOpen, setIsOpen, onClose, onUpdated }) => {
  const [album, setAlbum] = useState<SubjectAlbumDto | undefined>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async () => {
      try {
        if (!albumId) {
          throw new Error('올바른 대상이 아닙니다');
        }

        setIsLoading(true);
        const result = await albumApi.getById(albumId, isEval);
        setAlbum(result);
      } catch (error: any) {
        dispatch(
          modalOpen({
            type: 'alert',
            isOpen: true,
            title: '오류',
            message: error.message
              ? error.message
              : `오류가 발생하였습니다. [오류코드 ${error.status}]`,
          }),
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line
    [albumId],
  );

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, fetchData]);

  const handleClose = () => {
    setAlbum(undefined);
    setIsOpen(false);
  };

  const handleRemoved = () => {
    toast.info('삭제를 완료하였습니다');
    setIsOpen(false);
    onUpdated();
  };

  const handleModeChange = (mode: 'update' | 'detail') => {
    setIsEdit(mode === 'update');
  };

  const handleUpdated = (album?: SubjectAlbumDto) => {
    if (!album) {
      return;
    }
    toast.info('앨범을 수정하였습니다');
    setIsEdit(false);
    fetchData();
  };

  return (
    <Dialog className="relative z-[1000]" open={isOpen} onClose={() => { }}>
      {/* backdrop */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      {/* container */}
      <div className="fixed flex inset-0 w-full max-h-full items-center justify-center overflow-y-auto">
        <Dialog.Panel className="w-full max-w-full max-h-full lg:max-w-2xl xl:max-w-4xl">
          <div className="relative w-full p-4">
            {isLoading && (
              <div className="absolute w-full h-full flex items-center justify-center bg-white/75 rounded-lg">
                <TailSpin
                  height="48"
                  width="48"
                  color="#ff9e18"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  visible={true}
                />
              </div>
            )}
            {!isEdit && (
              <AlbumDetail
                album={album}
                isEval={isEval}
                onClose={handleClose}
                onRemoved={handleRemoved}
                onUpdateMode={() => {
                  handleModeChange('update');
                }}
              />
            )}
            {isEdit && (
              <AlbumForm
                album={album}
                onSave={handleUpdated}
                onClose={() => {
                  handleModeChange('detail');
                }}
              />
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
